//import Alpine from 'alpinejs'
//Alpine.start()

//console.log('hello!');

import * as $ from 'jquery';
//import { initCookieConsent } from './cookie-consent';

/* eslint-disable prettier/prettier */
//import { initContactForm } from './contact-form';
//import { Fancybox } from '@fancyapps/ui';
import Splide from '@splidejs/splide';

$(document).ready(() => {
    //initContactForm();
    //initCookieConsent();

    /* Navigation toggle */
    $('.nav-toggle').click(function () {
        $(this).toggleClass('nav-toggle--active');
        $('html, body').toggleClass('menu-open');
        $('.site-header').toggleClass('move-icon');
    });

    $('.nav-toggle').keypress(function (e) {
        const key = e.which;
        if (key == 13) {
            $(this).toggleClass('.nav-toggle--active');
            $('html, body').toggleClass('.menu-open');
            $('.site-header').toggleClass('.move-icon');
        }
    });

    $('.menu-background').mouseup(function (e) {
        const container = $('.menu-ul');

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.nav-toggle').toggleClass('nav-toggle--active');
            $('html, body').toggleClass('menu-open');
            $('.site-header').toggleClass('move-icon');
        }
    });

    //$('.lang').click(function () {
    //  $('.lang--active').toggleClass('lang--active');
    //  $(this).toggleClass('lang--active');
    //});

    /* Ad text toggle */
    $('.adtext-toggle').click(function () {
        $(this).toggleClass('adtext-toggle--active');
    });

    /* Sliding animation when nav-details are opened */
    $('details summary').each(function () {
        $(this).nextAll().wrapAll('<div class="wrap"></div>');
    });
    $('details').attr('open', '').find('.wrap').css('display', 'none');
    $('details summary').click(function (e) {
        e.preventDefault();
        $(this).parent('.nav-details').toggleClass('nav-details--active');
        $(this)
            .siblings('div.wrap')
            .slideToggle(function () {
                $(this).parent('details').toggleClass('open');
            });
    });

    /* Changing the navbar on scroll */
    $(function () {
        $(document).scroll(function () {
            const $nav = $('.site-header');
            $nav.toggleClass(
                'site-header--scrolled',
                $(this).scrollTop() > $nav.height()
            );
        });
    });
});

//Indicate which form-page the user is on
const descEl = document.getElementById(
    'umbraco_form_27841b0afd9d4f5ca90138a9a33003e6'
);
const descClassEl = document.getElementById('liDescription');

if (descEl != null) {
    descClassEl.classList.add('active-li');
}

const addressEl = document.getElementById(
    '8fcd6540-a029-435b-94f1-a338a7af2862'
);
const placeClassEl = document.getElementById('liPlace');

if (addressEl != null) {
    placeClassEl.classList.add('active-li');
}

const contactEl = document.getElementById(
    '44b437e0-bfcc-4327-f616-225976bc6bdf'
);
const contactClassEl = document.getElementById('liContact');

if (contactEl != null) {
    contactClassEl.classList.add('active-li');
}

/**
 * Slideblock functionality
 */
// Select all elements with the class 'image-slider-block'
const slideBlocks: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.image-slider-block'
);

// Loop through each element and create a slider
slideBlocks.forEach((slideBlock: HTMLElement) => {
    const sliderElement = slideBlock.querySelector('.slider');

    // Perform null check for the slider element
    if (sliderElement) {
        new Splide(sliderElement as HTMLElement, {
            // Cast sliderElement to HTMLElement
            perPage: 1,
            gap: 82,
            lazyLoad: 'nearby',
            label: 'Bildslides',
            focus: 0,
            omitEnd: true,
            type: 'loop',
            autoplay: true,
            interval: 4000,
            reducedMotion: {
                speed: 0,
                rewindSpeed: 0,
            },
            mediaQuery: 'min',
        }).mount();
    }
});

/**
 * Slider Hero functionality
 */
const heroSlider: HTMLElement | null = document.querySelector('.hero-slider');
if (heroSlider) {
    new Splide('.hero-slider', {
        perPage: 1,
        lazyLoad: 'nearby',
        label: 'Bildslides',
        focus: 0,
        omitEnd: true,
        reducedMotion: {
            speed: 0,
            rewindSpeed: 0,
        },
        type: 'loop',
        autoplay: true,
        interval: 4000,
        mediaQuery: 'min',
    }).mount();
}

// Function to remove the element with the class "splide__arrows"
const removeSplideArrows = () => {
    // Find all elements with class "hero splide__slide"
    const splideSlides = document.querySelectorAll('.hero.splide__slide');

    // Find all elements with class "splide__arrows"
    const splideArrows = document.querySelectorAll('.splide__arrows');

    // Check if there is only one slide
    if (splideSlides.length === 1 && splideArrows.length > 0) {
        // Remove the element with class "splide__arrows"
        splideArrows.forEach((arrow) => {
            arrow.remove();
        });
    }
};

// Call the function when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', removeSplideArrows);

/**
 * SearchPage
 */
const numberOfResultsSpan: HTMLSpanElement | null = document.querySelector(
    '.number-of-results'
);
if (numberOfResultsSpan) {
    const searchResults = document.querySelectorAll('.search-result');
    numberOfResultsSpan.textContent = parseInt(
        searchResults.length.toString(),
        10
    ).toString();
}

/**
 * Evenemangskalender
 */

const changeOddPlaceholders = () => {
    const datePickers = document.querySelectorAll('.new-pickers [x-ref="test"]');
    datePickers.forEach((picker, index) => {
        // Check if the index is odd
        //console.log('test');
        if (index % 2 !== 0) {
            picker.setAttribute('placeholder', 'Ange slutdatum/tid');
        }
    });
};

const addButton = document.querySelector('.btn--add') as HTMLButtonElement;
if (addButton) {
    addButton.addEventListener('click', changeOddPlaceholders);
}

// Move Events with passed StartDate but not yet passed EndDate.
// - Did this through TypeScript due to being stuck doing this thorugh the serverside,
// - So I chooce to go the TS-way to save time.

// Select the elements
const cardLinks = document.querySelectorAll('.card-link');
const eventsContainer = document.querySelector('.events-container');

// Collect and sort elements based on date
const elementsToMove: { cardLink: Element; date: Date; }[] = [];

cardLinks.forEach((cardLink) => {
    const cardDates = cardLink.querySelectorAll('.card__date');

    cardDates.forEach((cardDate) => {
        if (cardDate.classList.contains('status--in-progress')) {
            const dateText = cardDate.textContent.trim(); // Extract the entire text
            const dateValue = extractDateValue(dateText); // Extract only the date value
            if (dateValue) {
                elementsToMove.push({ cardLink, date: new Date(dateValue) });
            }
        }
    });
});

// Function to extract the date value from the text using regex
function extractDateValue(text: string) {
    // Regular expression to match date patterns (MM/DD/YYYY format as an example)
    const regex = /\b(\d{2}\/\d{2}\/\d{4})\b/;
    const match = text.match(regex);
    return match ? match[0] : null; // Return the matched date value or null if not found
}

// Sort elements based on date
elementsToMove.sort((a, b) => b.date.getTime() - a.date.getTime());

// Move sorted elements to the front of events-container
elementsToMove.forEach((element) => {
    if (eventsContainer) {
        eventsContainer.prepend(element.cardLink);
    }
});
